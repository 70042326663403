<template>
    <div class="news-container">
        <breadcrumb></breadcrumb>
        <div class="container-title">
            赏·非遗
        </div>
        <div class="news-category-box">
            <category @change="changeCategory" :categorys="categorys"></category>
        </div>
        <div class="search-box">
            <div class="drop-box">
                <el-dropdown @command='handleCommand'>
                    <span class="el-dropdown-link">
                        {{ batch?batch:'批次' }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown"  >
                        <el-dropdown-item :command="item" v-for="item in dict.batch" :key="item.id">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="drop-box">
                <el-dropdown @command='handleCommand2'>
                    <span class="el-dropdown-link">
                        {{ category?category:'类别' }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown"  >
                        <el-dropdown-item :command="item" v-for="item in dict.category" :key="item.id">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="drop-box">
                <el-dropdown @command='handleCommand3'>
                    <span class="el-dropdown-link">
                        {{ level?level:'级别' }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown"  >
                        <el-dropdown-item :command="item" v-for="item in dict.level" :key="item.id">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="drop-box">
                <input type="text" class="ipt" v-model="queryParams.title" value="" placeholder="请输入关键字" style="" />
            </div>
            <div class="drop-box btn" style=""  @click="handleQuery">
                搜索
            </div>
            <div class="drop-box" style="width: 100px; background-size: 100% 100%;font-size: 16px;"  @click="resetQuery">
               重置
            </div>

        </div>
        <div class="table">
            <el-table @row-click="handleClick" v-loading="loading" :header-cell-style="{ 'text-align': 'center', 'background': '#508ca3', 'color': '#fff', 'font-size': '16px' }"
                :cell-style="{ 'text-align': 'center', 'font-size': '16px' }" :data="list" style="width: 100%;">
                <el-table-column prop="projectCode" label="项目编号" v-if='categoryIndex === 0 && !loading' cloak />
                <el-table-column prop="inheritorName" label="传承人姓名" v-else />
                    
               
                <el-table-column prop="title" label="项目名称">
                </el-table-column>
                <el-table-column label="批次" align="center" prop="batch">
                    <template v-slot="scope">
                        <dict-tag :type="dict.batch" :value="scope.row.batch" />
                    </template>
                </el-table-column>
                <el-table-column label="类别" align="center" prop="category">
                    <template v-slot="scope">
                        <dict-tag :type="dict.category" :value="scope.row.category" />
                    </template>
                </el-table-column>
                <el-table-column label="级别" align="center" prop="level">
                    <template v-slot="scope">
                        <dict-tag :type="dict.level" :value="scope.row.level" />
                    </template>
                </el-table-column>

                <el-table-column prop="area" label="区域">
                </el-table-column>
            </el-table>
        </div>
        <!-- <div class="news-list-box">
          <router-link :to="'/kanfeiyi/detail/' + item.id" v-for="item in list" :key="item.id">
              <div class="news-item">
                  <div class="title-img" v-if="item.titleImg">
                      <img :src="item.titleImg" alt="">
                  </div>
                  <div class="news-info" :class="{ 'news-info-no-img': !item.titleImg }">
                      <div class="title">
                          {{ item.title }}.
                      </div>
                      <div class="description">
                          {{ item.description }}</div>
                      <div class="other">
                          <div class="time">
                              {{ formatDate(item.createTime, 'yyyy-MM-dd') }}
                          </div>
                          <div class="origin">
                              {{ item.origin }}
                          </div>
                      </div>
                  </div>
                  <div class="news-triangle"></div>
              </div>
          </router-link>
      </div> -->
        <!-- 分页组件 -->
    <!-- <pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList" /> -->

        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList" />
    </div>
</template>

<script>
import { getData, getDict} from "@/api/enjoy/enjoy";
import DictTag from '@/components/DictTag'
export default {
    name: 'enjoyList',
    components: {
        DictTag
    },
    data() {
        return {
            batch:'',
            category:'',
            level:'',
           
            loading:false,
            categoryIndex: 0,//分类下标
            title: '',
            queryParams:{
                pageNo: 1,//页码
                pageSize: 10,//每页条数
                batch:null,
                category:null,
                level:null,
                title:'',
            },
            //资讯分类
            categorys: [{
                name: '非遗项目',
                newsType: 1
            }, {
                name: '非遗传承人',
                newsType: 2
            }],
            pageNo: 1,//页码
            pageSize: 10,//每页条数
            total: 0,//总数量
            list: [],//列表
            dict: {
                batch: [],
                category: [],
                level: [],
            }
        }
    },
    methods: {
        handleClick(e){
            if(this.categoryIndex === 0){
                // 项目
                this.$router.push({path:'./project-detail/'+e.id})
            }else {
                // 人
                this.$router.push({path:'./people-detail/'+e.id})
            }

            console.log(e);
        },
        resetQuery(){
            this.queryParams={
                pageNo: 1,//页码
                pageSize: 10,//每页条数
                batch:null,
                category:null,
                level:null,
                title:''
            }
            this.batch=''
            this.category=''
            this.level=''
            this.handleQuery();
        },
        handleQuery(){
            this.queryParams.pageNo = 1;
                this.getList();
        },
        handleCommand(e){
            
                this.batch=e.label
            this.queryParams.batch=e.value
          
           
            // console.log(e);
        },
        handleCommand2(e){
          
            this.category=e.label
            this.queryParams.category=e.value
          
           
            // console.log(e);
        },
        handleCommand3(e){

            this.level=e.label
            this.queryParams.level=e.value
        },
        getDictList() {
            getDict('ich_inheritor_batch').then(res => { this.dict.batch = res.data })
            getDict('ich_inheritor_category').then(res => { this.dict.category = res.data })
            getDict('ich_inheritor_level').then(res => { this.dict.level = res.data })
        },
        /**
         * 获取资讯列表
         */
        getList() {
            this.loading=true
            let newsType = this.categorys[this.categoryIndex].newsType//当前分类

            getData(newsType, this.queryParams).then(res => {
                this.loading=false
                if (res.code == 0) {
                    // console.log(res);
                    this.list = res.data.list
                    this.total = res.data.total
                }
            })
        },
        /**
         * 选择分类
         */
        changeCategory(index) {
            this.categoryIndex = index
            this.resetQuery()
            this.getList()
        }
    },
    created() {
        console.log(this.$route.query.type);
        // 字典
        this.getDictList()
        //获取资讯列表
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.el-dropdown {
    // width: 100%;
    display: flex;
    justify-content: space-between;

    // align-content: center;
    align-items: center;
}

.el-dropdown-link {
    text-align: center;
    width: 100px;
    line-height: 50px;
    font-size: 16px;
    cursor: pointer;
    word-wrap: break-word; /*强制换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis;/*隐藏后添加省略号*/
  white-space: nowrap;/*强制不换行*/

}

.el-icon-arrow-down {
    margin-left: 20px;
    // font-size: 16px;
}

.news-container {
    width: 1200px;
    margin: 0 auto;

    .container-title {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #181B1C;
        font-family: XiaoWei-Regular;
    }

    .table {
        margin-top: 30px;
    }

    .search-box {
        display: flex;
        justify-content: flex-end;

        .drop-box {
            margin-left: 20px;
            display: flex;
            // align-content: center;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 50px;
            background: url('~@/assets/images/news-category-border.png') no-repeat center center scroll;
            background-size: contain;
            color: #979797;
            font-size: 14px;
            // background: url('../../assets/images/news-category-border.png') no-repeat center center scroll;
        }

        .ipt {
            display: inline-block;
            width: 120px;
            height: 40px;
            font-size: 16px;
        }

        .btn {
            background: url('~@/assets/images/news-category-border-active.png') no-repeat center center scroll;
            width: 100px;
            background-size: 100% 100%;
            // background-color: #fff;
            color: #fff;
            font-size: 16px;
        }
        .reset {
            width: 100px;
            background-size: 100% 100%;
            // background-color: #fff;
            // color: #fff;
            font-size: 16px;
            color: #000;
            background: url('~@/assets/images/news-category-border.png') no-repeat center center scroll;
            
        }
        

    }

    // .news-list-box {
    //     width: 100%;


    //     .news-item {
    //         background-image: url('../../assets/images/news-item-white-bg.png');
    //         transition: .3s ease-in-out;
    //         width: 100%;
    //         height: 190px;
    //         padding: 20px;
    //         display: flex;
    //         justify-content: space-between;
    //         border: 2px solid rgba(151, 151, 151, 0.103);
    //         margin-bottom: 30px;
    //         position: relative;

    //         .title-img {
    //             width: 185px;
    //             height: 140px;
    //             border-radius: 5px;
    //             overflow: hidden;

    //             img {
    //                 width: 100%;
    //                 height: 100%;
    //                 object-fit: cover;
    //             }
    //         }

    //         .news-info {
    //             width: calc(100% - 185px - 30px);
    //             display: flex;
    //             flex-direction: column;
    //             justify-content: space-around;

    //             .title {
    //                 transition: .3s;
    //                 width: 100%;
    //                 font-size: 16px;
    //                 color: #181B1C;
    //                 font-weight: bold;
    //                 overflow: hidden;
    //                 white-space: nowrap;
    //                 text-overflow: ellipsis;
    //             }

    //             .description {
    //                 width: 100%;
    //                 font-size: 14px;
    //                 color: #979797;
    //                 word-break: break-all;
    //                 text-overflow: ellipsis;
    //                 display: -webkit-box;
    //                 -webkit-box-orient: vertical;
    //                 -webkit-line-clamp: 2;
    //                 overflow: hidden;
    //             }


    //             .other {
    //                 width: 100%;
    //                 display: flex;
    //                 justify-content: flex-start;
    //                 gap: 30px;
    //                 font-size: 14px;
    //                 color: #979797;
    //             }
    //         }

    //         .news-info-no-img {
    //             width: 100%;
    //         }

    //         .news-triangle {
    //             position: absolute;
    //             transition: .3s;
    //             opacity: 0;
    //             left: 10%;
    //             bottom: 0;
    //             width: 0;
    //             height: 0;
    //             border-right: 10px solid transparent;
    //             border-left: 10px solid transparent;
    //             border-bottom: 10px solid #3A8DA6;
    //             z-index: 9;
    //         }

    //         .news-triangle::before {
    //             content: "";
    //             position: absolute;
    //             top: 2px;
    //             transform: translate(-50%, 0);
    //             width: 0;
    //             height: 0;
    //             border-right: 8px solid transparent;
    //             border-left: 8px solid transparent;
    //             border-bottom: 8px solid #ffffff;
    //         }

    //         .news-triangle::after {
    //             content: "";
    //             position: absolute;
    //             top: 5px;
    //             transform: translate(-50%, 0);
    //             width: 0;
    //             height: 0;
    //             border-right: 5px solid transparent;
    //             border-left: 5px solid transparent;
    //             border-bottom: 5px solid #3A8DA6;
    //         }

    //     }

    //     .news-item:hover {
    //         background-image: url('../../assets/images/news-item-bg.png');
    //         border-bottom: 2px solid #3A8DA6;

    //         .title {
    //             color: #3A8DA6;
    //         }

    //         .news-triangle {
    //             opacity: 1;
    //         }

    //     }

    // }
}
</style>